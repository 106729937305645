import React, { useEffect, useState, useCallback } from 'react';
import { Button, Avatar, formattingHelpers } from 'day8-ui';
import Image from 'next/image';
import store from 'store';
import { useLuckyModal } from '../../context/LuckyModalContext';

const NavbarUser = () => {
  const [user, setUser] = useState<any | null>(null);
  const fetchUser = useCallback(async () => {
    if (typeof window === 'undefined') {
      return; // exit early if server-side
    }

    if (window.location.host === 'localhost:3000') {
      return;
    }

    try {
      const response = await fetch('/api/profile', {
        credentials: 'same-origin'
      });

      const result = await response.json();

      if (result.status === 'success') {
        const fetchedUser = result.data;

        if (fetchedUser !== user) {
          store.set('user', fetchedUser);
          setUser(fetchedUser);
        }
      } else {
        store.remove('user');
        store.remove('reduxPersist:user');
        setUser(null);
      }
    } catch (e) {
      console.warn('Couldn’t fetch the current user', e);
    }
  }, [user]);
  useEffect(() => {
    const storedUser = store.get('user');
    setUser(storedUser);
    fetchUser();
  }, []);
  if (!user) {
    return (
      <>
        <div className='dui-hidden lg:dui-flex dui-items-center dui-gap-2'>
          <a href="/account/login">
            <Button buttonType='TERTIARY'>Log in</Button>
          </a>
          <BookButton />
        </div>
        <div className='lg:dui-hidden'>
          <a href="/account/login">
            <Button className='dui-w-full dui-justify-center' buttonType='SECONDARY'>Log in</Button>
          </a>
        </div>
      </>
    )
  }

  const fbImageUrl = user && user.fbUid && `https://graph.facebook.com/v2.9/${user.fbUid}/picture?type=square`;
  const profileImageUrl = user.gravatarImageUrl || fbImageUrl;

  return (
    <div>
      <div className='dui-hidden lg:dui-flex dui-justify-end dui-items-center dui-gap-2'>
        <a href="/account">
          <Avatar>
            {profileImageUrl && <Image src={profileImageUrl} alt={user.name} width={64} height={64} />}
            {!profileImageUrl && <span>{formattingHelpers.getInitialsFromName(user.firstName, user.lastName)}</span>}
          </Avatar>
        </a>
        <div>
          <BookButton />
        </div>
      </div>
      <div className='lg:dui-hidden'>
        <a href="/account">
          <div className='dui-flex dui-items-center dui-gap-2'>
            <div>
              <Avatar>
                {profileImageUrl && <Image src={profileImageUrl} alt={user.name} width={64} height={64} />}
                {!profileImageUrl && <span>{formattingHelpers.getInitialsFromName(user.firstName, user.lastName)}</span>}
              </Avatar>
            </div>
            <div className=''>
              <p className='dui-font-semibold'>{user.name}</p>
              <p>{user.email}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}
export default NavbarUser;

const BookButton = () => {
  const { toggle } = useLuckyModal();
  return <Button onClick={toggle} buttonSize='SMALL'>Book now</Button>
}