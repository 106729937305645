import React from 'react';

type TywMiniLogoProps = {
  fill?: string;
  className?: string;
}
const TywMiniLogo: React.FC<TywMiniLogoProps> = ({ fill = "#122F51", className }) => (
  <svg className={`${className}`} width="1335" height="1420" viewBox="0 0 1335 1420" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{'Yacht Week Logo'}</title>
    <path d="M201.82 866.15L128.7 969.16H125.82L52.3499 866.15H0.129883L103.86 1005.89V1109.61H147.07V1005.89L250.07 866.15H201.82Z" fill={fill} />
    <path d="M424.78 1046.86C392.78 1030.61 357.47 1022.82 311.03 1020.9L360.51 910.1H363.39L414.89 1024.62L424.78 1046.86ZM337.11 866.15L225.1 1109.61H271.2L291.73 1063.51L294.93 1056.44C377.28 1057.57 413.25 1075.43 453.01 1109.51L453.02 1109.5L453.07 1109.61H500.97L389.32 866.15H337.11Z" fill={fill} />
    <path d="M648.79 1073.24C591.89 1073.24 558.39 1037.59 558.39 987.53C558.39 938.19 591.88 902.54 645.91 902.54C683.36 902.54 708.22 916.59 725.14 945.03L760.07 922.7C739.19 883.44 699.21 861.84 645.91 861.84C567.75 861.84 514.1 915.86 514.1 987.53C514.1 1059.92 567.76 1113.94 648.79 1113.94C700.65 1113.94 742.79 1088.73 763.68 1049.84L730.9 1028.59C716.14 1056.31 686.25 1073.24 648.79 1073.24Z" fill={fill} />
    <path d="M1003.85 954.75H858.71V866.15H815.49V1109.61H858.71V994.73H1003.85V1109.61H1047.43V866.15H1003.85V954.75Z" fill={fill} />
    <path d="M1091.7 866.15V905.78H1191.47V1109.61H1235.04V905.78H1334.08V866.15H1091.7Z" fill={fill} />
    <path d="M661.379 643.13L533.119 569.53C519.619 584.02 505.319 598.22 490.159 612.1L607.649 680.45C626.669 668.7 644.519 656.21 661.379 643.13Z" fill={fill} />
    <path d="M843.499 354.31C851.039 310.9 852.039 266.36 845.869 228.41L724.499 158.53C716.589 214.1 703.399 269.57 683.619 323.81L829.519 407.97C822.609 430.1 813.899 451.77 803.409 472.83L656.589 388.3C639.669 423.83 619.559 458.61 595.869 492.28L736.199 573.23C754.379 552.62 770.379 530.99 784.269 508.58L905.149 578.2C923.149 553.39 938.969 525.26 951.659 493.24C960.469 471.03 966.569 449.18 970.369 427.79L843.499 354.31Z" fill={fill} />
    <path d="M661.39 643.13L760.06 699.75C791.04 683.98 821.67 664.1 849.89 639.07L736.2 573.24C736.19 573.24 693.68 617.66 661.39 643.13Z" fill={fill} />
    <path d="M966.749 298C930.619 139.9 790.599 25.4098 761.189 2.81979L756.779 0.279785C807.649 74.5398 836.799 152.09 845.879 228.41L966.749 298Z" fill={fill} />
    <path d="M515.009 728.24L416.699 671.83C399.899 683.99 382.299 695.82 363.859 707.29L445.539 754.29C469.729 746.47 492.879 737.74 515.009 728.24L564.419 756.59C598.199 752.72 642.949 744.78 690.639 728.73L607.659 680.45C579.059 698.11 540.649 717.98 515.009 728.24Z" fill={fill} />
    <path d="M366.979 1368.79H364.099L299.269 1176.11H251.369L185.469 1368.43H182.949L119.199 1176.11H70.9395L157.369 1419.57H209.229L272.979 1243.82H275.859L338.529 1419.57H390.389L477.179 1176.11H431.809L366.979 1368.79Z" fill={fill} />
    <path d="M550.82 1304.69H693.8V1265.07H550.82V1216.09H706.75V1176.11H507.6V1419.57H710.36V1379.96H550.82V1304.69Z" fill={fill} />
    <path d="M802.239 1304.69H945.229V1265.07H802.239V1216.09H958.189V1176.11H759.029V1419.57H961.799V1379.96H802.239V1304.69Z" fill={fill} />
    <path d="M1263.28 1419.57L1158.03 1262.08C1193.91 1236.04 1219.13 1203.38 1234.31 1175.93H1185.11C1149.69 1231.17 1103.68 1253.67 1053.68 1263.84V1176.1H1010.46V1419.56H1053.68V1332.4V1305.38C1078.74 1301.43 1101.28 1293.9 1121.41 1284.07L1211.78 1419.55H1263.28V1419.57Z" fill={fill} />
  </svg>
);

export default TywMiniLogo;